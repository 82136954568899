import React from 'react';
import Layout from '../../components/Layout/layout';
import HourlyLeaveCalculator from '../../Views/Portal/Tools/Calculators/Hourly-Leave-Calculator/Hourly-Leave-Calculator';

const HourlyLeaveCalculatorPage: React.FC = () => {
  return (
    <Layout>
      <HourlyLeaveCalculator />
    </Layout>
  );
};

export default HourlyLeaveCalculatorPage;
