// extracted by mini-css-extract-plugin
export var Button = "Hourly-Leave-Calculator-module--Button--24e55";
export var Calculator = "Hourly-Leave-Calculator-module--Calculator--4954d";
export var CalculatorInner = "Hourly-Leave-Calculator-module--CalculatorInner--1d0e3";
export var CalculatorMain = "Hourly-Leave-Calculator-module--CalculatorMain--1f2cf";
export var CalculatorSidebar = "Hourly-Leave-Calculator-module--CalculatorSidebar--e902b";
export var CalculatorSidebarInner = "Hourly-Leave-Calculator-module--CalculatorSidebarInner--05cd5";
export var Checkbox = "Hourly-Leave-Calculator-module--Checkbox--f6554";
export var CheckboxInner = "Hourly-Leave-Calculator-module--CheckboxInner--bfa07";
export var CheckboxInnerSlider = "Hourly-Leave-Calculator-module--CheckboxInnerSlider--4e984";
export var Eligible = "Hourly-Leave-Calculator-module--Eligible--d3699";
export var Form = "Hourly-Leave-Calculator-module--Form--4c4fe";
export var Full = "Hourly-Leave-Calculator-module--Full--5c674";
export var Green = "Hourly-Leave-Calculator-module--Green--54193";
export var Info = "Hourly-Leave-Calculator-module--Info--682b2";
export var Input = "Hourly-Leave-Calculator-module--Input--14541";
export var Print = "Hourly-Leave-Calculator-module--Print--44778";
export var PrintResults = "Hourly-Leave-Calculator-module--PrintResults--5aca0";
export var Red = "Hourly-Leave-Calculator-module--Red--4f0d3";
export var Results = "Hourly-Leave-Calculator-module--Results--7e5c0";
export var Total = "Hourly-Leave-Calculator-module--Total--c8d94";