import { faCalendar, faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faArrowRight, faLink, faPrint, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Banner from '../../../../../components/Banner/Banner';
import Breadcrumb from '../../../../../components/Breadcrumb/Breadcrumb';
import LoginForm from '../../../../../components/Login/Login';

import UserProfile from '../../../../../components/User/User';
import { UserContext } from '../../../../../Context/UserContext';
import * as styles from './Hourly-Leave-Calculator.module.scss';

interface Props {
  hideBanner?: boolean;
}

const HourlyLeaveCalculator: React.FC<Props> = ({ hideBanner }) => {
  const { User } = useContext(UserContext);

  const [employeeStart, setEmployeeStart] = useState<string>("");
  const [checkResults, setCheckResults] = useState<boolean>(false);
  const [hoursPerWeek, setHoursPerWeek] = useState<number>(0);
  const [daysPerWeek, setDaysPerWeek] = useState<number>(0);

  const entitlement: number = 5.6;
  const maxEntitlement: number = 28;

  const PrintClasses = {
    "PrintTotal": {
      width: "calc(50% - 20px)",
      padding: "35px 25px 25px",
      margin: "10px",
      float: "left",
      backgroundColor: "#eee",
      borderRadius: "5px",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      boxSizing: "border-box"
    } as React.CSSProperties,
    "PrintTotalH1": {
      width: "100%",
      float: "left",
      textAlign: "center",
      margin: "0px 0px 10px 0px",
      color: "var(--PrimaryColor)",
      fontSize: "30px",
      fontWeight: "normal",
    } as React.CSSProperties,
    "PrintTotalText": {
      width: "100%",
      float: "left",
      textAlign: "center",
      margin: "0px 0px 5px 0px",
      color: "#333",
      fontSize: "15px"
    } as React.CSSProperties,
    "PrintDescription": {
      width: "100%",
      float: "left",
      textAlign: "left",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "15px",
      color: "#333",
      fontSize: "15px"
    } as React.CSSProperties,
    "PrintTitle": {
      fontWeight: "normal",
      width: "100%",
      float: "left",
      textAlign: "center",
      fontFamily: "'Lato',Helvetica,Arial,Lucida,sans-serif",
      marginBottom: "10px",
      fontSize: "25px"
    } as React.CSSProperties
  }

  const WeeksRemaining = (from: string) => {
    var startDate = new Date(from);
    var endOfYear = new Date(`${startDate.getFullYear()}-12-31`);
    var daysOfYear: Date[] = [];

    for (var d = startDate; d <= endOfYear; d.setDate(d.getDate() + 1)) {
      var date = new Date(d);
      daysOfYear.push(date);
    }

    if (daysOfYear[daysOfYear.length - 1].toLocaleDateString(window.navigator.language) !== endOfYear.toLocaleDateString(window.navigator.language))
      daysOfYear.push(endOfYear);

    return (daysOfYear.length / 7);
  }

  const GetEntitled = () => {
    let days = ((hoursPerWeek * entitlement) / (hoursPerWeek / daysPerWeek));
    if (days >= maxEntitlement)
      days = maxEntitlement;
    return days;
  }

  const isLeapYear = (year: number) => {
    let isLeap: boolean = (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0));
    return isLeap ? 366 : 365;
  }

  const GetRemainingHoliday = (date: string) => {
    let entitled = GetEntitled();
    let hoursPerDay = (hoursPerWeek / daysPerWeek);
    let workDaysPerYear = ((isLeapYear(new Date(date).getFullYear()) / 7) * daysPerWeek);
    let remainingWorkDays = (WeeksRemaining(date) * daysPerWeek);

    if (entitled === maxEntitlement) {
      let totalHours = (((maxEntitlement / workDaysPerYear) * remainingWorkDays) * hoursPerDay);
      let totalDays = totalHours / hoursPerDay;
      if (totalDays >= maxEntitlement)
        return maxEntitlement * hoursPerDay;
    }
    return parseFloat((((entitled / workDaysPerYear) * remainingWorkDays) * hoursPerDay).toFixed(1));
  }

  const PrintResults = () => {
    var prtContent = document.getElementById("Results");
    var WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
    if (prtContent && WinPrint) {
      WinPrint.document.write(prtContent.innerHTML);
      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    }
  }

  return (<>
    {hideBanner ? <></> : <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Hourly Leave Calculator</title><meta charSet='utf-8' />
      </Helmet>
      <Banner />
      <Breadcrumb Trail={[{
        Text: "Calculators",
        To: "/Calculators"
      }, {
        Text: "Hourly Leave",
        To: ""
      }]} />
    </>}

    <div className={styles.Calculator}>
      <div className={styles.CalculatorInner}>
        <div className={styles.CalculatorMain}>
          <div className={styles.Info}>
            <p>
              <b>People working irregular hours (like shift workers or term-time workers) are entitled to paid time off for every hour they work.</b>
              <b>Statutory paid holiday entitlement is limited to 28 days. For example, staff working 6 days a week are only entitled to 28 days’ paid holiday.</b>
            </p>
          </div>

          <div className={styles.Form}>
            <div className={`${styles.Input} ${styles.Full}`}>
              <label>Employee Start Date</label>
              <i>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </i>
              <input disabled={checkResults} type="date" value={employeeStart} onChange={(e) => setEmployeeStart(e.target.value)} />
              <i>
                <FontAwesomeIcon icon={faCalendar} />
              </i>
            </div>

            <div className={`${styles.Input}`}>
              <label>Hours Per Week</label>
              <input disabled={checkResults} type="number" min={0} max={168} value={hoursPerWeek} onChange={(e) => setHoursPerWeek(parseInt(e.target.value))} />
            </div>

            <div className={`${styles.Input}`}>
              <label>Days Per Week</label>
              <input disabled={checkResults} type="number" min={1} max={7} value={daysPerWeek} onChange={(e) => setDaysPerWeek(parseInt(e.target.value))} />
            </div>

            <div className={styles.Button}>
              {!checkResults ?
                <button type="button" disabled={!hoursPerWeek || !daysPerWeek || !employeeStart} onClick={() => setCheckResults(true)}>Calculate <i><FontAwesomeIcon icon={faArrowRight} /></i></button>
                : <button type="button" onClick={() => { setCheckResults(false); setEmployeeStart(""); setHoursPerWeek(0); setDaysPerWeek(0) }}>Reset <i><FontAwesomeIcon icon={faSyncAlt} /></i></button>
              }
            </div>
          </div>

          {checkResults ? <>
            <div className={styles.Results}>
              <div className={styles.Total}>
                <h1>{(GetRemainingHoliday(`${new Date(employeeStart).getFullYear()}-01-01`) / (hoursPerWeek / daysPerWeek)).toFixed(1)} Days</h1>
                <b>Available To Accrue</b>
                <p>Based on the average of {GetRemainingHoliday(`${new Date(employeeStart).getFullYear()}-01-01`).toFixed(1)} worked hours per year with an average of {(Math.round((hoursPerWeek / daysPerWeek) * 100) / 100).toFixed(1)} hours worked per day</p>
              </div>

              <div className={styles.Total}>
                <h1>{parseInt(`${(GetRemainingHoliday(employeeStart) / (hoursPerWeek / daysPerWeek)) * 10}`) / 10} Days</h1>
                <b>Left To Accrue for {new Date(employeeStart).getFullYear()}</b>
                <p>Based on the average of {GetRemainingHoliday(employeeStart).toFixed(1)} hours left to accrue from {new Date(employeeStart).toLocaleDateString(window.navigator.language)} to {new Date(`${new Date(employeeStart).getFullYear()}-12-31`).toLocaleDateString(window.navigator.language)}</p>
              </div>
            </div>

            <div className={styles.PrintResults} id="Results">
              <h2 style={PrintClasses["PrintTitle"]}>Results of Hourly Holiday Request</h2>
              <p style={PrintClasses["PrintDescription"]}>These results are based on the employee starting <b>{new Date(employeeStart).getFullYear()}</b> on <b>{new Date(employeeStart).toDateString().replace(new Date(employeeStart).getFullYear().toString() + " ", "")}</b>, and having an average working week of {hoursPerWeek} across {daysPerWeek} days.</p>

              <div style={PrintClasses["PrintTotal"]}>
                <h1 style={PrintClasses["PrintTotalH1"]}>{(GetRemainingHoliday(`${new Date(employeeStart).getFullYear()}-01-01`) / (hoursPerWeek / daysPerWeek)).toFixed(1)} Days</h1>
                <b style={PrintClasses["PrintTotalText"]}>Available To Accrue</b>
                <p style={PrintClasses["PrintTotalText"]}>Based on the average of {GetRemainingHoliday(`${new Date(employeeStart).getFullYear()}-01-01`).toFixed(1)} worked hours per year with an average of {(Math.round((hoursPerWeek / daysPerWeek) * 100) / 100).toFixed(1)} hours worked per day</p>
              </div>

              <div style={PrintClasses["PrintTotal"]}>
                <h1 style={PrintClasses["PrintTotalH1"]}>{parseInt(`${(GetRemainingHoliday(employeeStart) / (hoursPerWeek / daysPerWeek)) * 10}`) / 10} Days</h1>
                <b style={PrintClasses["PrintTotalText"]}>Left To Accrue for {new Date(employeeStart).getFullYear()}</b>
                <p style={PrintClasses["PrintTotalText"]}>Based on the average of {GetRemainingHoliday(employeeStart).toFixed(1)} hours left to accrue from {new Date(employeeStart).toLocaleDateString(window.navigator.language)} to {new Date(`${new Date(employeeStart).getFullYear()}-12-31`).toLocaleDateString(window.navigator.language)}</p>
              </div>
            </div>

            <div className={styles.Print}>
              <button type="button" onClick={() => PrintResults()}><i><FontAwesomeIcon icon={faPrint} /></i> Print Results</button>
            </div>
          </> : <></>}
        </div>
        <div className={styles.CalculatorSidebar}>
          {hideBanner ? <></> : !User.ID ? <LoginForm /> : <UserProfile />}

          <div className={styles.CalculatorSidebarInner}>
            <h1>References</h1>
            <i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights">(GOV.uk) Holiday Entitlement</a>
            <ul>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights/calculate-leave-entitlement">(GOV.uk) Calculate Leave Entitlement</a></li>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights/holiday-pay-the-basics">(GOV.uk) Holiday Pay</a></li>
              <li><i><FontAwesomeIcon icon={faLink} /></i><a target="_blank" href="https://www.gov.uk/holiday-entitlement-rights/taking-holiday-before-leaving-a-job">(GOV.uk) Taking Holiday Before Leaving a Job</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </>);
};

export default HourlyLeaveCalculator;
